<template>
  <div class="divider"></div>
</template>

<script>
export default {
  name: "ContentDivider",
};
</script>

<style scoped>
.divider {
  margin: 25px auto;
  width: 80%;
  height: 4px;
  background-color: var(--light);
  border-radius: 2px;
}
</style>
