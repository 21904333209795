var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageIntro',{attrs:{"image":require('@/assets/icons/preparation.svg')}},[_vm._v(" Wähle deine Form und los geht's!"),_c('br'),_vm._v(" Hake erledigte Schritte ab, indem du drauftippst. ")]),_c('ContentDivider'),_c('PanSelect',{staticClass:"mb-4"}),_vm._l((_vm.recipeSteps),function(step,stepIndex){return _c('div',{key:("step-" + stepIndex)},[_c('h4',{staticClass:"headline"},[_vm._v(_vm._s(step.part))]),_c('ul',{staticClass:"instructions mb-4"},_vm._l((step.instructions),function(instruction,instructionIndex){return _c('li',{key:("instruction-" + stepIndex + "-" + instructionIndex),class:[
          'mb-3',
          {
            'instruction-checked': _vm.checked(stepIndex, instructionIndex),
          } ]},[_c('div',{staticClass:"d-flex",on:{"click":function($event){return _vm.toggle({ stepIndex: stepIndex, instructionIndex: instructionIndex })}}},[(instruction.icon === 'timer')?_c('img',{staticClass:"mr-3 align-self-center instruction-icon",attrs:{"alt":"instruction icon","src":require("@/assets/icons/bell.svg")}}):_vm._e(),_c('span',{staticClass:"align-self-center flex-grow-1"},[_vm._v(" "+_vm._s(instruction.text)+" ")])]),_c('ul',{staticClass:"instruction-ingredients"},_vm._l((instruction.ingredients),function(ingredient,ingredientIndex){return _c('li',{key:("ingredient-" + stepIndex + "-" + instructionIndex + "-" + ingredientIndex),class:{
              'ingredient-checked': _vm.checked(
                stepIndex,
                instructionIndex,
                ingredientIndex
              ),
            },on:{"click":function($event){return _vm.toggle({ stepIndex: stepIndex, instructionIndex: instructionIndex, ingredientIndex: ingredientIndex })}}},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.getScaledAmount( _vm.parts[step.part][ingredient.name], ingredient.relativeAmount )))]),(_vm.parts[step.part][ingredient.name].unit)?_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.parts[step.part][ingredient.name].unit))]):_vm._e(),_c('strong',[_vm._v(_vm._s(_vm.parts[step.part][ingredient.name].name))])])}),0)])}),0)])}),_vm._m(0),_c('div',{staticClass:"text-center mt-4"},[_c('b-link',{staticClass:"text-muted small",on:{"click":function($event){return _vm.reset()}}},[_c('BIconXCircle',{staticClass:"mr-1"}),_vm._v("Rezept zurücksetzen ")],1)],1)],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center mt-4"},[_c('img',{staticClass:"complete-image",attrs:{"alt":"cake","src":require("@/assets/icons/cake.svg")}})])}]

export { render, staticRenderFns }